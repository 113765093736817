import React from 'react';
import { 
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import { BloqueInfo } from './BloqueInfo';

export function Estudios(){

    const bg = useColorModeValue('gray.200', 'gray.700')
    const color = useColorModeValue('gray.800', 'gray.200')

    return(
        <>

                <Box p={5}></Box>
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    // Incluyo la cabecera solo en el primero
                    cabecera='Educación'
                    titulo='Técnico Superior Administración de Sistemas Informáticos en Red'
                    fecha='Septiembre 2024 - Actualmente cursando'
                    descripcion='IES Suárez de Figueroa - Estudiando a distancia el Ciclo de Grado Superior ASIR para amplicar conocimientos en redes y sistemas.'
                    badge1='redes locales'
                    badge2='sistemas operativos'
                    badge3='servicios en red'
                    badge4='seguridad informática'
                    badge5='bases de datos'
                    colorBadge={'blue'}
                />
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico en sistemas microinformáticos y redes'
                    fecha='Septiembre 2022 - Junio 2024'
                    descripcion='IES Castelar - Este Ciclo de Grado Medio SMR me ha proporcionado interesantes conociemientos sobre redes.'
                    badge1='redes locales'
                    badge2='aplicaciones web'
                    badge3='servicios en red'
                    badge4='seguridad informática'
                    badge5='montaje y mantenimiento de equipos'
                    colorBadge={'blue'}
                />
                {/* <BloqueInfo 
                    fecha='Octubre 2022 - Junio 2023'
                    descripcion='Asignaturas aprobadas en el curso 22-23.'
                    badge1='Aplicaciones web'
                    badge2='Empresa e iniciativa emprendedora'
                    badge3='Servicios en red'
                    badge4='Sistemas operativos monopuestos'
                    badge5='Seguridad informática'
                    badge6='Sistemas operativos en red'
                    badge7='Montaje y mantenimiento de equipos'
                    
                    colorBadge={'green'}
                /> */}
                {/* <Box p={3}></Box> */}
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    // Incluyo la cabecera solo en el primero
                    titulo='FP1 Programación de aplicaciones informáticas'
                    fecha='Julio 2007 - Junio 2008'
                    descripcion='Junta de Extremadura SEXPE.'
                    badge1='.NET'
                    badge2='Windows'
                    badge3='Database'
                    colorBadge={'blue'}
                />
                {/* <Box p={3}></Box> */}
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='FP1 de Artes Gráficas'
                    fecha='Mayo 2003 - Octubre 2003'
                    descripcion='Junta de Extremadura SEXPE.'
                    badge1='Adobe'
                    badge2='Poser'
                    badge3='Composición del color'
                    colorBadge={'blue'}
                />
                {/* <Box p={3}></Box> */}
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='FP2 Informática de Gestión'
                    fecha='Septiembre 1995 - Junio 1997'
                    descripcion='Colegio Virgen de Guadalupe'
                    badge1='Cobol'
                    badge2='Ms-Dos'
                    badge3='Informática básica'
                    badge4='hardware'
                    colorBadge={'blue'}
                />
                {/* <Box p={3}></Box> */}
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='FP1 Electricidad y electrónica'
                    fecha='Septiembre 1993 - Junio 1995'
                    descripcion='Colegio Virgen de Guadalupe'
                    badge1='Electricidad y Electrónica'
                    badge2='Automoción'
                    badge3='Metal'
                    colorBadge={'blue'}
                />

                <Box p={3}></Box>
                <Box p={5}></Box>

        </>
    )
}
